const AppConfig = {
    region: "ap-southeast-2",
    homeUrl: "https://vicki.stuff.co.nz/",
    cognitoDomain: "auth.vicki.stuff.co.nz",
    clientId: "7eieih56mae9nuc4chd30phcri"
}

const AmplifyConfig = {
    Auth: {
        region: AppConfig.region,
        userPoolId: "ap-southeast-2_sUZPpfREv",
        userPoolWebClientId: AppConfig.clientId,
        oauth: {
            domain: AppConfig.cognitoDomain,
            scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: AppConfig.homeUrl,
            redirectSignOut: AppConfig.homeUrl,
            responseType: 'code'
        }
    },
    aws_appsync_region: AppConfig.region,
    aws_appsync_graphqlEndpoint: "https://vtk4rdgxqnaera72uhtzulrzq4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

export default AmplifyConfig;
export {AppConfig}